import React from 'react';
import styled from 'styled-components';

const HeroStyles = styled.div`
    display: grid;
    grid-template-columns: 66.6% 33.3%;
    grid-template-areas: 'title .'
                        '. author';
    position: relative;

    padding: 3rem 0;

    h1 {
        grid-area: title;
    }
    p {
        grid-area: author;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        img {
            height: 4em;
            width: auto;
            border-radius: 50%;
            margin-right: 1em;
        }
    }
    colorscape {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
        transform: translate(var(--translate-x), var(--translate-y));
        background:radial-gradient(ellipse at center, hsl(var(--mouse-hue), 50%, 50%) 0, transparent 60%);
        opacity: 0.1;
    }

    @media screen and (max-width: 1024px) {
        grid-template-columns: 1fr;
        grid-template-areas: 'title'
                            'author';
    }

    @media (hover: none) {
        colorscape {
            display: none;
        }
    }
`;

export const Hero = ({ heading, author }) => {
    function handleMouseMove(e) {
        let mouse = {
            x: e.clientX,
            y: e.clientY
        };
        let center = {
            x: e.currentTarget.clientWidth / 2,
            y: e.currentTarget.clientHeight / 2
        };
        let modAmount = 0.1;
        let mouseMod = {
            x: (mouse.x - center.x) * modAmount,
            y: (mouse.y - center.y) * modAmount
        };
        e.currentTarget.style.setProperty('--translate-x', `${mouseMod.x}px`);
        e.currentTarget.style.setProperty('--translate-y', `${mouseMod.y}px`);
        e.currentTarget.style.setProperty('--mouse-hue', `${mouse.x}`);
    }
    return (
        <HeroStyles onMouseMove={handleMouseMove}>
            <h1>{ heading }</h1>
            <p>
                <img width='1000' height='1000' src={author.profile} alt={author.name} />
                {author.desc}
            </p>
            <colorscape></colorscape>
        </HeroStyles>
    )
};