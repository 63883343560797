import { graphql } from 'gatsby';
import React, {useState} from 'react';
import { useSiteMetadata } from '../hooks/useSiteMetadata';
import SEO from 'react-seo-component';
import {Layout} from '../components/Layout';
import {Hero} from '../components/page/Hero';
import {Tease} from '../components/Tease';
import ProfileImage from '../../static/images/profile.jpg';
import '../styles/tease-list.scss';

export default ({data}) => {
    const {
        description,
        title,
        siteUrl,
        siteLanguage,
        siteLocale,
        twitterUsername,
        authorName,
        authorDesc,
        frontPage
      } = useSiteMetadata();
    return (
        <Layout>
            <SEO
                title={title}
                description={description}
                pathname={siteUrl}
                siteLanguage={siteLanguage}
                siteLocale={siteLocale}
                twitterUsername={twitterUsername}
            />
            <Hero heading={frontPage.heading} author={{
                name: authorName,
                desc: authorDesc,
                profile: ProfileImage
            }} />
            <div id='teaseList'>
                {data.allMdx.nodes.map(({ id,excerpt, frontmatter,fields }) => (
                    <Tease key={id} post={{excerpt, frontmatter,fields}} />
                ))}
            </div>
        </Layout>
    );
};

export const query = graphql`
  query SITE_INDEX_QUERY {
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      nodes {
        id
        excerpt(pruneLength: 250)
        frontmatter {
          title
          date(formatString: "DD/MM/YY")
          hero {
              publicURL
          }
        }
        fields {
            slug
        }
      }
    }
}`;