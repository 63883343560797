import AniLink from 'gatsby-plugin-transition-link/AniLink';
import React from 'react';
import '../styles/tease.scss';

export const Tease = ({ post }) => {
    const randomSetSize = () => {
        let rand = Math.floor(Math.random() * 5)
        return rand % 5 === 0
    }
    return (
        <article className={`tease`}>
            <AniLink swipe direction="left" to={post.fields.slug}>
                <h2>{post.frontmatter.title}</h2>
                <p>{post.excerpt}</p>
                <p className='date'>{post.frontmatter.date}</p>
            </AniLink>
        </article>
    );
};